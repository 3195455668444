import { combineReducers } from "redux";
import preferences from "./preferences";
import settings from "./settings";
import media from "./media";
import * as actions from "./actions";
import createRootState from "./createRootState";
const reducers = {
    preferences,
    settings,
    media,
};
const sliceReducers = combineReducers(reducers);
const rootReducer = (state = createRootState(), action) => {
    switch (action.type) {
        case actions.restore.TRIGGER:
            state = Object.assign(Object.assign({}, state), action.payload);
            break;
        default:
            break;
    }
    return sliceReducers(state, action);
};
export default rootReducer;
