import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import * as ReactDOM from "react-dom/client";
import ErrorBoundary from "./components/ErrorBoundary";
import Provider from "./components/Provider";
import FallbackMessage from "./components/FallbackMessage";
import configureStore from "./state/configureStore";
import Router from "./containers/Router";
const { store } = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(FallbackMessage, { message: "Loading Provider" }) }, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(FallbackMessage, { message: "Loading Router" }) }, { children: _jsx(Router, {}) })) })) })) }) }));
