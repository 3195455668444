const createPreferencesState = () => ({
    allIds: ["darkMode"],
    byId: {
        darkMode: {
            id: "darkMode",
            flagged: false,
        },
    },
});
export default createPreferencesState;
