import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = { failed: false };
    }
    static getDerivedStateFromError(_) {
        return { failed: true };
    }
    componentDidCatch(error, errorInfo) {
        const { onError } = this.props;
        if (typeof onError !== "function")
            return;
        try {
            onError(error, errorInfo);
        }
        catch (e) {
            // do nothing
        }
    }
    render() {
        const { fallback = "Error", children } = this.props;
        return _jsx(Wrap, { children: this.state.failed ? fallback : children });
    }
}
const Wrap = ({ children }) => React.isValidElement(children) ? children : _jsx(_Fragment, { children: children });
export default ErrorBoundary;
