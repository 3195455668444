import { handleActions } from "redux-actions";
import produce from "immer";
import * as actions from "./actions";
import createPreferencesState from "./createPreferencesState";
const initialState = createPreferencesState();
const onToggleDarkMode = produce((draft) => {
    draft.byId.darkMode.flagged = !draft.byId.darkMode.flagged;
});
const onChangeDarkModePreference = produce((draft, action) => {
    draft.byId.darkMode.flagged = action.payload;
});
const reducer = handleActions({
    [actions.toggleDarkMode.TRIGGER]: onToggleDarkMode,
    [actions.changeDarkModePreference.TRIGGER]: onChangeDarkModePreference,
}, initialState);
export default reducer;
